import { getDataObjectById } from 'o365.vue.ts'

const handler = function (jsonAlias,sourceField,pSourceItem) {
	return {
		get: function (obj, prop) {
			if (['[object Object]', '[object Array]'].indexOf(Object.prototype.toString.call(obj[prop])) > -1) {
				return new Proxy(obj[prop], handler(jsonAlias,sourceField,pSourceItem));
			}
			return obj[prop];
		},
		set: function (obj, prop, value) {
            if(value === null || value === ""){
                delete obj[prop];
            }else{
                obj[prop] = value;
            }
			
            updateChange(jsonAlias,sourceField,pSourceItem);
            return true;
		}
	};
};


function getItem(pItem,jsonAlias,sourceField,pSourceItem){
    if(pItem && pItem.constructor === String){
        pItem = parseJson(pItem);
    }
    if(pItem === null){
        pItem = {};
    }
    return new Proxy(pItem,handler(jsonAlias,sourceField,pSourceItem));
}

function updateChange(jsonAlias,sourceField,sourceItem){
    const change = {};
    const ds = getDataObjectById(sourceItem.dataObjectId, sourceItem.appId);
    //change[sourceField] = JSON.stringify(ds.data[sourceItem.index][jsonAlias].item);
    change[sourceField] = JSON.stringify(clean(ds.data[sourceItem.index][jsonAlias]));
    
    ds.updateItem(sourceItem.index,change);
}

function clean(pObject) {
    Object
        .entries(pObject)
        .forEach(([k, v]) => {
            if (v && typeof v === 'object') {
                clean(v);
            }
            if (v && typeof v === 'object' && !Object.keys(v).length || v === null || v === undefined) {
                if (Array.isArray(pObject)) {
                    pObject.splice(parseInt(k), 1);
                } else {
                    delete pObject[k];
                }
            }
        });
    return pObject;
}




function parseJson(pJson){
        try{
            return JSON.parse(pJson);
        }catch{
            return {};
        }
    }

export {getItem}